import React, {Component} from 'react';
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/es/FormControl";

class CronAdvanced extends Component {

    constructor(props) {
        super(props);

        this.state = {
            cron: '0 0 14 */1 * *',
        };

        this.onCronChange = this.onCronChange.bind(this);
    }

    componentDidMount() {
        this.props.onChange(this.state.cron);
    }

    render() {
        return (
            <div className="cron-builder-content">
                <InputGroup className="m-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="cronExpressionLabel">Unix Cron expression</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        type="text"
                        aria-label="Unix Cron expression"
                        aria-describedby="cronExpressionLabel"
                        value={this.state.cron}
                        onChange={this.onCronChange}
                    />
                </InputGroup>
            </div>
        );
    }

    onCronChange(event) {
        this.setState({cron: event.target.value});
        this.props.onChange(event.target.value);
    }
}

export default CronAdvanced;
