import React, {Component} from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {LinkContainer} from "react-router-bootstrap";
import PropTypes from "prop-types";

class AppHeader extends Component {
    render() {
        return (
            <Navbar bg="dark" variant="dark" expand="lg">
                <Navbar.Brand href="/">{process.env.REACT_APP_NAME}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <LinkContainer to="/dashboard">
                            <Nav.Link>Dashboard</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/profile">
                            <Nav.Link>Profile</Nav.Link>
                        </LinkContainer>
                    </Nav>
                    {this.props.authenticated ? (
                        <Nav>
                            <Nav.Link onClick={this.props.onLogout}>Logout</Nav.Link>
                        </Nav>
                    ) : (
                        <Nav>
                            <LinkContainer to="/login">
                                <Nav.Link>Login</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/signup">
                                <Nav.Link>Signup</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

AppHeader.propTypes = {
    onLogout: PropTypes.func.isRequired,
    authenticated: PropTypes.bool.isRequired,
};

export default AppHeader;
