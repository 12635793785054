import React, {Component} from 'react';
import InputGroup from "react-bootstrap/InputGroup";
import TimePicker from "../util/TimePicker";

class CronMonthly extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hour: "0",
            minute: "0",
            day: "1",
            everyMonth: "1",
            weekdayEveryMonth: "1",
            nthWeekday: "0",
            weekday: "MON",
            dayOfEveryMonth: true,
            theNWeekdayOfEveryNMonth: false,
        };

        this.onHourChange = this.onHourChange.bind(this);
        this.onMinuteChange = this.onMinuteChange.bind(this);
        this.onRadioChange = this.onRadioChange.bind(this);
        this.onDayChange = this.onDayChange.bind(this);
        this.onEveryMonthsChange = this.onEveryMonthsChange.bind(this);
    }

    componentDidMount() {
        this.props.onChange(`0 ${this.state.minute} ${this.state.hour} ${this.state.day} */${this.state.everyMonth} *`);
    }

    render() {
        return (
            <div className="cron-builder-content">
                <InputGroup className="m-2">
                    <InputGroup.Prepend>
                        <InputGroup.Radio checked={this.state.dayOfEveryMonth}
                                          id="day-of-every-month"
                                          name="monthly-cron-method"
                                          onChange={this.onRadioChange}/>
                    </InputGroup.Prepend>
                    <div className="radio-input-group-description">
                        <span>Day</span>
                        <input className="m-2"
                               type="number"
                               onChange={this.onDayChange}
                               value={this.state.day}
                               disabled={!this.state.dayOfEveryMonth}/>
                        <span>of every</span>
                        <input className="m-2"
                               type="number"
                               onChange={this.onEveryMonthsChange}
                               value={this.state.everyMonth}
                               disabled={!this.state.dayOfEveryMonth}/>
                        <span>month(s)</span>
                    </div>
                </InputGroup>

                <div className="m-2">
                    <span>
                        Start time
                    </span>

                    <TimePicker onMinuteChange={this.onMinuteChange}
                                onHourChange={this.onHourChange}
                                disabled={false}/>
                </div>
            </div>
        );
    }

    onHourChange(event) {
        const hour = event.target.value;
        this.setState({hour: hour});

        if (this.state.dayOfEveryMonth) {
            this.props.onChange(`0 ${this.state.minute} ${hour} ${this.state.day} */${this.state.everyMonth} *`);
        }
    }

    onMinuteChange(event) {
        const minute = event.target.value;
        this.setState({minute: minute});

        if (this.state.dayOfEveryMonth) {
            this.props.onChange(`0 ${minute} ${this.state.hour} ${this.state.day} */${this.state.everyMonth} *`);
        }
    }

    onDayChange(event) {
        const day = event.target.value;
        if (day && day !== "" && day > 0 && day < 32) {
            this.setState({day: day});
            this.props.onChange(`0 ${this.state.minute} ${this.state.hour} ${day} */${this.state.everyMonth} *`);
        }
    }

    onEveryMonthsChange(event) {
        const everyMonth = event.target.value;
        if (everyMonth && everyMonth !== "" && everyMonth > 0 && everyMonth < 13) {
            this.setState({everyMonth: everyMonth});
            this.props.onChange(`0 ${this.state.minute} ${this.state.hour} ${this.state.day} */${this.state.everyMonth} *`);
        }
    }

    onRadioChange(event) {
        if (event.target.id === "day-of-every-month") {
            this.setState({
                dayOfEveryMonth: true,
                theNWeekdayOfEveryNMonth: false,
            });
        }
    }
}

export default CronMonthly;
