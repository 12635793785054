import React, {Component} from 'react';
import TimePicker from "../util/TimePicker";
import InputGroup from "react-bootstrap/InputGroup";

class CronWeekly extends Component {

    constructor(props) {
        super(props);

        this.state = {
            minute: "0",
            hour: "0",
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
        };

        this.onHourChange = this.onHourChange.bind(this);
        this.onMinuteChange = this.onMinuteChange.bind(this);
        this.onMondayChange = this.onMondayChange.bind(this);
        this.onTuesdayChange = this.onTuesdayChange.bind(this);
        this.onWednesdayChange = this.onWednesdayChange.bind(this);
        this.onThursdayChange = this.onThursdayChange.bind(this);
        this.onFridayChange = this.onFridayChange.bind(this);
        this.onSaturdayChange = this.onSaturdayChange.bind(this);
        this.onSundayChange = this.onSundayChange.bind(this);
    }

    componentDidMount() {
        this.props.onChange(`0 ${this.state.minute} ${this.state.hour} * * ${CronWeekly.getWeekDays(this.state)}`);
    }

    render() {
        return (
            <div className="cron-builder-content">
                <InputGroup className="m-2">

                    <div className="m-3">
                        <label>
                            <input
                                type="checkbox"
                                checked={this.state.monday}
                                onChange={this.onMondayChange}/>
                            Monday
                        </label>
                    </div>
                    <div className="m-3">
                        <label>
                            <input
                                type="checkbox"
                                checked={this.state.tuesday}
                                onChange={this.onTuesdayChange}/>
                            Tuesday
                        </label>
                    </div>
                    <div className="m-3">
                        <label>
                            <input
                                type="checkbox"
                                checked={this.state.wednesday}
                                onChange={this.onWednesdayChange}/>
                            Wednesday
                        </label>
                    </div>
                    <div className="m-3">
                        <label>
                            <input
                                type="checkbox"
                                checked={this.state.thursday}
                                onChange={this.onThursdayChange}/>
                            Thursday
                        </label>
                    </div>
                    <div className="m-3">
                        <label>
                            <input
                                type="checkbox"
                                checked={this.state.friday}
                                onChange={this.onFridayChange}/>
                            Friday
                        </label>
                    </div>
                    <div className="m-3">
                        <label>
                            <input
                                type="checkbox"
                                checked={this.state.saturday}
                                onChange={this.onSaturdayChange}/>
                            Saturday
                        </label>
                    </div>
                    <div className="m-3">
                        <label>
                            <input
                                type="checkbox"
                                checked={this.state.sunday}
                                onChange={this.onSundayChange}/>
                            Sunday
                        </label>
                    </div>
                </InputGroup>

                <div className="m-2">
                    <span>
                        Start time
                    </span>

                    <TimePicker onMinuteChange={this.onMinuteChange}
                                onHourChange={this.onHourChange}
                                disabled={false}/>
                </div>
            </div>
        );
    }

    onHourChange(event) {
        const hour = event.target.value;
        this.setState({hour: hour});
        this.props.onChange(`0 ${this.state.minute} ${hour} * * ${CronWeekly.getWeekDays(this.state)}`);
    }

    onMinuteChange(event) {
        const minute = event.target.value;
        this.setState({minute: minute});
        this.props.onChange(`0 ${minute} ${this.state.hour} * * ${CronWeekly.getWeekDays(this.state)}`);
    }

    onMondayChange(event) {
        this.setState({monday: event.target.checked});
        const state = {...this.state, monday: event.target.checked};
        this.props.onChange(`0 ${this.state.minute} ${this.state.hour} * * ${CronWeekly.getWeekDays(state)}`);
    }

    onTuesdayChange(event) {
        this.setState({tuesday: event.target.checked});
        const state = {...this.state, tuesday: event.target.checked};
        this.props.onChange(`0 ${this.state.minute} ${this.state.hour} * * ${CronWeekly.getWeekDays(state)}`);
    }

    onWednesdayChange(event) {
        this.setState({wednesday: event.target.checked});
        const state = {...this.state, wednesday: event.target.checked};
        this.props.onChange(`0 ${this.state.minute} ${this.state.hour} * * ${CronWeekly.getWeekDays(state)}`);
    }

    onThursdayChange(event) {
        this.setState({thursday: event.target.checked});
        const state = {...this.state, thursday: event.target.checked};
        this.props.onChange(`0 ${this.state.minute} ${this.state.hour} * * ${CronWeekly.getWeekDays(state)}`);
    }

    onFridayChange(event) {
        this.setState({friday: event.target.checked});
        const state = {...this.state, friday: event.target.checked};
        this.props.onChange(`0 ${this.state.minute} ${this.state.hour} * * ${CronWeekly.getWeekDays(state)}`);
    }

    onSaturdayChange(event) {
        this.setState({saturday: event.target.checked});
        const state = {...this.state, saturday: event.target.checked};
        this.props.onChange(`0 ${this.state.minute} ${this.state.hour} * * ${CronWeekly.getWeekDays(state)}`);
    }

    onSundayChange(event) {
        this.setState({sunday: event.target.checked});
        const state = {...this.state, sunday: event.target.checked};
        this.props.onChange(`0 ${this.state.minute} ${this.state.hour} * * ${CronWeekly.getWeekDays(state)}`);
    }

    static getWeekDays(state) {
        let weekDays = [];

        if (state.monday) {
            weekDays.push("MON")
        }
        if (state.tuesday) {
            weekDays.push("TUE")
        }
        if (state.wednesday) {
            weekDays.push("WED")
        }
        if (state.thursday) {
            weekDays.push("THU")
        }
        if (state.friday) {
            weekDays.push("FRI")
        }
        if (state.saturday) {
            weekDays.push("SAT")
        }
        if (state.sunday) {
            weekDays.push("SUN")
        }

        return weekDays.join(',');
    }
}

export default CronWeekly;
