import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {ACCESS_TOKEN} from "../../../constants";

class TokenAuthenticate extends Component {

    constructor(props) {
        super(props);

        localStorage.setItem(ACCESS_TOKEN, props.match.params.token);
    }

    render() {
        return <Redirect to='/dashboard'/>;
    }
}

export default TokenAuthenticate
