import React, {Component} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './PageHeader.css';
import PropTypes from "prop-types";

class PageHeader extends Component {

    render() {
        return (
            <Row>
                <Col style={{
                    borderBottom: "1px solid #e3e3e3", marginBottom: "20px"}}>
                    <h1 className="page-title" style={{display: "inline-block"}}>{this.props.title}</h1>
                    {this.renderSubtitle()}
                </Col>
            </Row>
        )
    }

    renderSubtitle() {
        const subtitle = this.props.subtitle;

        if (subtitle) {
            return <span style={{marginLeft: "20px"}}>{subtitle}</span>
        }
    }
}

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
};

export default PageHeader;
