import {ACCESS_TOKEN, API_BASE_URL} from '../constants';

const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    });

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
        .then(response => response.json()
            .then(json => {
                if (response.status === 401) {
                    localStorage.removeItem(ACCESS_TOKEN);
                    window.location = '/login';
                }
                if (!response.ok) {
                    return Promise.reject(json);
                }
                return json;
            })
            // .catch(reason => Promise.resolve())
        )
};

export function getCurrentUser() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + "/users/me",
        method: 'GET'
    });
}

export function updateCurrentUser(user) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + "/users/" + user.id,
        method: 'PUT',
        body: JSON.stringify(user)
    });
}

export function getConversationHealthChartData({startDate, endDate}) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: `${API_BASE_URL}/rpc/charts/conversation-health?startDate=${startDate.valueOf()}&endDate=${endDate.valueOf()}`,
        method: 'GET',
    });
}


export function getTrendChartData({startDate, endDate}) {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: `${API_BASE_URL}/rpc/charts/trend?startDate=${startDate.valueOf()}&endDate=${endDate.valueOf()}`,
        method: 'GET',
    });
}


export function optOut() {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: `${API_BASE_URL}/rpc/opt-out`,
        method: 'POST',
    });
}
