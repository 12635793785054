import React, {Component} from 'react';

class CronMinutes extends Component {

    constructor(props) {
        super(props);

        this.state = {
            minutes: "30",
        };

        this.onMinutesChange = this.onMinutesChange.bind(this)
    }

    componentDidMount() {
        this.props.onChange(`0 */${this.state.minutes} * * * *`)
    }

    render() {
        return (
            <div className="cron-builder-content">
                <div>
                    <span>Every</span>
                    <input className="m-2"
                           type="number"
                           onChange={this.onMinutesChange}
                           value={this.state.minutes}/>
                    <span>minute(s)</span>
                </div>
                <div>

                </div>
            </div>
        );
    }

    onMinutesChange(event) {
        const minutes = event.target.value;

        let result;
        if (minutes && minutes !== "" && minutes > 0 && minutes <= 60) {
            result = minutes;
        } else if (minutes === 60) {
            result = 60;
        } else {
            result = 1;
        }

        this.setState({minutes: result});

        this.props.onChange(`0 */${result} * * * *`)
    }
}

export default CronMinutes;
