import React, {Component} from 'react';
import './ProfileInfo.css';
import Role from "./Role";

class ProfileInfo extends Component {

    render() {
        const {user} = this.props;

        return (
            <div className="shadow profile-info-container">
                <div>
                    <img className="profile-img"
                         src={user.imageUrl}
                         alt={user.twitterDisplayName}/>
                </div>
                <div>
                    <div className="profile-name">
                        {user.firstName} {user.lastName}
                    </div>
                    <div className="profile-screen-name">
                        {user.twitterDisplayName}
                    </div>
                    <div className="profile-roles">
                        {user.roles.map((role) => <Role role={role} key={role.id}/>)}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProfileInfo;
