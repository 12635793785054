import React, {Component} from 'react';
import './Dashboard.css';
import Container from "react-bootstrap/Container";
import PageHeader from "../../common/PageHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TrendChart from "./charts/TrendChart";
import ConversationHealthChart from "./charts/ConversationHealthChart";
import {DateRangePicker} from 'react-dates';
import * as moment from 'moment';
import {Alert, ListGroup} from "react-bootstrap";
import {getConversationHealthChartData, getTrendChartData} from "../../../util/APIUtils";

class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            startDate: moment().subtract(1, 'day'),
            endDate: moment(),
            trendData: [],
            conversationHealthData: [],
        };

        this.onDateSelectionChange = this.onDateSelectionChange.bind(this)
    }

    componentDidMount() {
        const {startDate, endDate} = this.state;
        this.onDateSelectionChange({startDate, endDate})
    }

    fetchTrendData(startDate, endDate) {
        if (startDate && endDate) {
            getTrendChartData({startDate, endDate})
                .then(({data}) => this.setState({trendData: data}));
        }
    }

    fetchConversationHealthData(startDate, endDate) {
        if (startDate && endDate) {
            getConversationHealthChartData({startDate, endDate})
                .then(({data}) => this.setState({conversationHealthData: data}));
        }
    }

    onDateSelectionChange({startDate, endDate}) {
        if (startDate !== this.state.startDate || endDate !== this.state.endDate) {
            this.setState({
                startDate,
                endDate
            });
        }

        this.fetchTrendData(startDate, endDate);
        this.fetchConversationHealthData(startDate, endDate);
    }

    render() {
        return (
            <Container>
                <PageHeader title="Dashboard" subtitle="Data originating from your entire timeline."/>

                <div style={{marginLeft: "5px"}}>Select the time period to be shown in the diagrams bellow</div>

                <Row style={{marginTop: "20px"}}>
                    <Col xs={12}>
                        <div className={"range-picker"}>
                            <DateRangePicker
                                startDate={this.state.startDate}
                                startDateId="start-date"
                                endDate={this.state.endDate}
                                endDateId="end-date"
                                onDatesChange={this.onDateSelectionChange}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({focusedInput})}
                                hideKeyboardShortcutsPanel={true}
                                showClearDates={true}
                                isOutsideRange={() => false}
                                displayFormat="DD/MM/YYYY"
                            />
                        </div>
                    </Col>
                </Row>

                {this.getCardSubtitle()}

                <Row>
                    <Col xs={12} sm={12} md={6} style={{marginTop: "20px"}}>
                        <TrendChart data={this.state.trendData} startDate={this.state.startDate} endDate={this.state.endDate}/>
                    </Col>
                    <Col xs={12} sm={12} md={6} style={{marginTop: "20px"}}>
                        <ConversationHealthChart data={this.state.conversationHealthData} startDate={this.state.startDate} endDate={this.state.endDate}/>
                    </Col>
                </Row>

                <Row style={{marginTop: "50px", marginBottom: "100px"}}>
                    <Col xs={12} style={{marginBottom: "10px"}}>
                        <h3>Definitions</h3>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <div style={{border: "1px solid #e3e3e3", borderRadius: "5px", padding: "20px"}}>
                            <p><strong>Hate speech definition:</strong></p>
                            <p>Does it target a person or group?</p>
                            <p>Does it contains a hateful attack?</p>
                            <ListGroup>
                                <ListGroup.Item>a) Violent speech</ListGroup.Item>
                                <ListGroup.Item>b) Support for death/disease/harm</ListGroup.Item>
                                <ListGroup.Item>c) Statement of inferiority relating to a group they identify with
                                    (groups like LGBTQI)</ListGroup.Item>
                                <ListGroup.Item>d) Call for segregation</ListGroup.Item>
                            </ListGroup>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <div style={{border: "1px solid #e3e3e3", borderRadius: "5px", padding: "20px"}}>
                            <p><strong>Personal attack definition:</strong></p>
                            <p>Does the comment contain a personal attack or harassment?</p>
                            <ListGroup style={{marginBottom: "15px"}}>
                                <ListGroup.Item>a) Targeted at the recipient of the message (i.e. you
                                    suck).</ListGroup.Item>
                                <ListGroup.Item>b) Targeted at a third party (i.e. Bob sucks).</ListGroup.Item>
                                <ListGroup.Item>c) Being reported or quoted (i.e. Bob said Henri
                                    sucks).</ListGroup.Item>
                                <ListGroup.Item>d) Another kind of attack or harassment.</ListGroup.Item>
                                <ListGroup.Item>e) This is not an attack or harassment.</ListGroup.Item>
                            </ListGroup>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    getCardSubtitle() {
        const lastRow = this.state.trendData[this.state.trendData.length - 1];

        if (lastRow) {
            const number = parseFloat(lastRow.hateSpeech).toFixed(2);
            if (lastRow.hateSpeech > 0) {
                return <Alert variant="info" className="chart-increase">
                    Last 5 minutes: {number}% increase
                </Alert>
            } else {
                return <Alert variant="danger" className="chart-decrease">
                    Last 5 minutes: {number}% decrease
                </Alert>
            }
        } else {
            return "";
        }
    }
}

export default Dashboard
