import React, {Component} from 'react';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CronMinutes from "./choices/CronMinutes";
import CronHourly from "./choices/CronHourly";
import CronDaily from "./choices/CronDaily";
import CronWeekly from "./choices/CronWeekly";
import CronMonthly from "./choices/CronMonthly";
import "./CronBuilder.css";
import Button from "react-bootstrap/Button";
import CronAdvanced from "./choices/CronAdvanced";
import PropTypes from "prop-types";

class CronBuilder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tabKey: 'minutes',
            cron: props.value || "* * * * *"
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(key) {
        this.setState({tabKey: key});
    }

    render() {
        let cronValidation = this.props.isValidCron || (() => true);

        let component;
        switch (this.state.tabKey) {
            case "minutes":
                component = <CronMinutes onChange={this.handleChange}/>;
                break;
            case "hourly":
                component = <CronHourly onChange={this.handleChange}/>;
                break;
            case "daily":
                component = <CronDaily onChange={this.handleChange}/>;
                break;
            case "weekly":
                component = <CronWeekly onChange={this.handleChange}/>;
                break;
            case "monthly":
                component = <CronMonthly onChange={this.handleChange}/>;
                break;
            case "advanced":
                component = <CronAdvanced onChange={this.handleChange}/>;
                break;
            default:
                component = <div/>
        }

        return (
            <div className="cron-builder-container">
                <Tabs defaultActiveKey="minutes"
                      id="con-builder-tabs"
                      activeKey={this.state.tabKey}
                      onSelect={this.handleSelect}>
                    <Tab eventKey="minutes" title="Minutes"/>
                    <Tab eventKey="hourly" title="Hourly"/>
                    <Tab eventKey="daily" title="Daily"/>
                    <Tab eventKey="weekly" title="Weekly"/>
                    <Tab eventKey="monthly" title="Monthly"/>
                    <Tab eventKey="advanced" title="Advanced"/>
                </Tabs>
                {component}

                <Button variant="primary"
                        className="float-right"
                        disabled={!cronValidation(this.state.cron)}
                        onClick={() => this.props.onSubmit(this.state.cron)}>
                    Save
                </Button>
            </div>
        );
    }

    handleChange(cron) {
        this.setState({
            cron: cron
        });
    }
}

CronBuilder.propTypes = {
    value: PropTypes.string.isRequired,
    isValidCron: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default CronBuilder;
