export const API_BASE_URL = process.env.REACT_APP_URL;
export const ACCESS_TOKEN = 'accessToken';


export const TWITTER_AUTH_URL = API_BASE_URL + '/signin/twitter';

export const EVERY_MONTH_FIRST_AT_9_O_CLOCK = 'everyMonthFirstAt9OClock';
export const EVERY_WEEKDAY_AT_9_O_CLOCK = 'everyWeekdayAt9OClock';
export const EVERY_DAY_AT_9_O_CLOCK = 'everyDayAt9OClock';


export const THRESHOLD_OPTIONS = {
    LOW: 0,
    MEDIUM: 1,
    HIGH: 2,
    VERY_HIGH: 3,
};
