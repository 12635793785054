import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import AppHeader from '../common/AppHeader';
import Login from '../auth/login/Login';
import Signup from '../auth/signup/Signup';
import Dashboard from '../panel/dashboard/Dashboard';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import {getCurrentUser} from '../../util/APIUtils';
import {ACCESS_TOKEN} from '../../constants';
import PrivateRoute from '../common/PrivateRoute';
import Alert from 'react-s-alert';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import './App.css';
import TokenAuthenticate from "../../component/auth/token-authenticate/TokenAuthenticate";
import Profile from "../panel/profile/Profile";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            authenticated: false,
            currentUser: null,
            loading: false
        };

        this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    loadCurrentlyLoggedInUser() {
        this.setState({
            loading: true
        });

        getCurrentUser()
            .then(response => {
                this.setState({
                    currentUser: response,
                    authenticated: true,
                    loading: false
                });
            }).catch(error => {
            this.setState({
                loading: false
            });
        });
    }

    handleLogout() {
        localStorage.removeItem(ACCESS_TOKEN);
        this.setState({
            authenticated: false,
            currentUser: null
        });
    }

    componentDidMount() {
        this.loadCurrentlyLoggedInUser();
    }

    render() {
        if (this.state.loading) {
            return <LoadingIndicator/>
        }

        return (
            <div className="app">
                <div className="app-top-box">
                    <AppHeader authenticated={this.state.authenticated} onLogout={this.handleLogout}/>
                </div>
                <div className="app-body">
                    <Switch>
                        <Route exact path="/" render={() => <Redirect to="/dashboard"/>}/>

                        <PrivateRoute path="/dashboard"
                                      authenticated={this.state.authenticated}
                                      currentUser={this.state.currentUser}
                                      component={Dashboard}/>

                        <PrivateRoute path="/profile"
                                      authenticated={this.state.authenticated}
                                      currentUser={this.state.currentUser}
                                      component={Profile}
                                      onLogout={this.handleLogout}/>

                        <Route path="/auth/:token" component={TokenAuthenticate}/>

                        <Route path="/login"
                               render={(props) => <Login authenticated={this.state.authenticated} {...props} />}/>
                        <Route path="/signup"
                               render={(props) => <Signup authenticated={this.state.authenticated} {...props} />}/>

                        <Route component={NotFound}/>
                    </Switch>
                </div>
                <Alert stack={{limit: 3}}
                       timeout={3000}
                       position='bottom-right' effect='slide' offset={65}/>
            </div>
        );
    }
}

export default App;
