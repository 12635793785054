import React, {Component} from 'react';
import './ThresholdRateChooser.css';
import {Button} from "react-bootstrap";
import {THRESHOLD_OPTIONS} from "../../constants";
import PropTypes from "prop-types";

const SELECTED_CLASS = "selected";
const DISPLAY_BLOCK = "d-block";
const DISPLAY_NONE = "d-none";

class ThresholdRateChooser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            colorBarWidth: 0,
            selected: THRESHOLD_OPTIONS.LOW,
            lowButtonClassName: SELECTED_CLASS,
            mediumButtonClassName: "",
            highButtonClassName: "",
            veryHighButtonClassName: "",
            lowTooltipClassName: DISPLAY_BLOCK,
            mediumTooltipClassName: DISPLAY_NONE,
            highTooltipClassName: DISPLAY_NONE,
            veryHighTooltipClassName: DISPLAY_NONE,
            lowButtonPosition: 0,
            mediumButtonPosition: 0,
            highButtonPosition: 0,
            veryHighButtonPosition: 0,
        };

        this.valueChanged = this.valueChanged.bind(this);
        this.getColorBarWidth = this.getColorBarWidth.bind(this);
        this.getGroupWrapperWidth = this.getGroupWrapperWidth.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.selected) {
            this.valueChanged(nextProps.value);
        }
    }

    componentDidMount() {
        this.setState(state => {
            return {
                colorBarWidth: this.getColorBarWidth(state.selected),
                lowButtonPosition: document.getElementById("custom-radio-group--low-button").offsetLeft,
                mediumButtonPosition: document.getElementById("custom-radio-group--medium-button").offsetLeft,
                highButtonPosition: document.getElementById("custom-radio-group--high-button").offsetLeft,
                veryHighButtonPosition: document.getElementById("custom-radio-group--very-high-button").offsetLeft,
            }
        });
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        const {
            colorBarWidth,
            lowButtonClassName,
            lowTooltipClassName,
            mediumButtonClassName,
            mediumTooltipClassName,
            highButtonClassName,
            highTooltipClassName,
            veryHighButtonClassName,
            veryHighTooltipClassName,
            lowButtonPosition,
            mediumButtonPosition,
            highButtonPosition,
            veryHighButtonPosition,
        } = this.state;
        const {disabled} = this.props;

        return (
            <div id="group-wrapper" style={{marginTop: "10px", marginBottom: "25px"}}>
                <div className={"custom-radio-group--spinner " + (disabled ? "disabled" : "")} style={{width: colorBarWidth > 0 ? colorBarWidth : 0}}/>
                <div className="custom-radio-group--wrapper">
                    <Button className={["custom-radio-group--button", lowButtonClassName]}
                            onClick={() => this.valueChanged(THRESHOLD_OPTIONS.LOW)}
                            id={"custom-radio-group--low-button"}
                            variant="secondary"
                            disabled={disabled}
                    >
                    </Button>

                    <Button className={["custom-radio-group--button", mediumButtonClassName]}
                            onClick={() => this.valueChanged(THRESHOLD_OPTIONS.MEDIUM)}
                            id={"custom-radio-group--medium-button"}
                            variant="secondary"
                            disabled={disabled}
                    >
                    </Button>

                    <Button className={["custom-radio-group--button", highButtonClassName]}
                            onClick={() => this.valueChanged(THRESHOLD_OPTIONS.HIGH)}
                            id={"custom-radio-group--high-button"}
                            variant="secondary"
                            disabled={disabled}
                    >
                    </Button>

                    <Button className={["custom-radio-group--button", veryHighButtonClassName]}
                            onClick={() => this.valueChanged(THRESHOLD_OPTIONS.VERY_HIGH)}
                            id={"custom-radio-group--very-high-button"}
                            variant="secondary"
                            disabled={disabled}
                    >
                    </Button>
                </div>
                <div>
                    <div role="tooltip" className={`fade show popover bs-popover-bottom tooltip-box ${lowTooltipClassName}`}
                         id="popover-positioned-bottom">
                        <div className="arrow" style={{left: `${lowButtonPosition - 23}px`}}/>
                        <div className="popover-header h3">Low</div>
                        <div className="popover-body">An alert is sent when the DACHS model has identified at least one instance of hate speech with at least LOW confidence.</div>
                    </div>

                    <div role="tooltip" className={`fade show popover bs-popover-bottom tooltip-box ${mediumTooltipClassName}`}
                         id="popover-positioned-bottom">
                        <div className="arrow" style={{left: `${mediumButtonPosition - 24}px`}}/>
                        <div className="popover-header h3">Medium</div>
                        <div className="popover-body">An alert is sent when the DACHS model has identified at least one instance of hate speech with at least MEDIUM confidence.</div>
                    </div>

                    <div role="tooltip" className={`fade show popover bs-popover-bottom tooltip-box ${highTooltipClassName}`}
                         id="popover-positioned-bottom">
                        <div className="arrow" style={{left: `${highButtonPosition - 24}px`}}/>
                        <div className="popover-header h3">High</div>
                        <div className="popover-body">An alert is sent when the DACHS model has identified at least one instance of hate speech with at least HIGH confidence.</div>
                    </div>

                    <div role="tooltip" className={`fade show popover bs-popover-bottom tooltip-box ${veryHighTooltipClassName}`}
                         id="popover-positioned-bottom">
                        <div className="arrow" style={{left: `${veryHighButtonPosition - 25}px`}}/>
                        <div className="popover-header h3">Very High</div>
                        <div className="popover-body">An alert is sent when the DACHS model has identified at least one instance of hate speech with at least VERY HIGH confidence.</div>
                    </div>
                </div>
            </div>
        )
    }

    valueChanged(value) {
        switch (value) {
            case  THRESHOLD_OPTIONS.LOW:
                this.setState({
                    selected: THRESHOLD_OPTIONS.LOW,
                    colorBarWidth: this.getColorBarWidth(THRESHOLD_OPTIONS.LOW),
                    lowButtonClassName: SELECTED_CLASS,
                    mediumButtonClassName: "",
                    highButtonClassName: "",
                    veryHighButtonClassName: "",
                    lowTooltipClassName: DISPLAY_BLOCK,
                    mediumTooltipClassName: DISPLAY_NONE,
                    highTooltipClassName: DISPLAY_NONE,
                    veryHighTooltipClassName: DISPLAY_NONE,
                });

                this.props.onChange(THRESHOLD_OPTIONS.LOW);
                break;
            case  THRESHOLD_OPTIONS.MEDIUM:
                this.setState({
                    selected: THRESHOLD_OPTIONS.MEDIUM,
                    colorBarWidth: this.getColorBarWidth(THRESHOLD_OPTIONS.MEDIUM),
                    lowButtonClassName: SELECTED_CLASS,
                    mediumButtonClassName: SELECTED_CLASS,
                    highButtonClassName: "",
                    veryHighButtonClassName: "",
                    lowTooltipClassName: DISPLAY_NONE,
                    mediumTooltipClassName: DISPLAY_BLOCK,
                    highTooltipClassName: DISPLAY_NONE,
                    veryHighTooltipClassName: DISPLAY_NONE,
                });

                this.props.onChange(THRESHOLD_OPTIONS.MEDIUM);
                break;
            case  THRESHOLD_OPTIONS.HIGH:
                this.setState({
                    selected: THRESHOLD_OPTIONS.HIGH,
                    colorBarWidth: this.getColorBarWidth(THRESHOLD_OPTIONS.HIGH),
                    lowButtonClassName: SELECTED_CLASS,
                    mediumButtonClassName: SELECTED_CLASS,
                    highButtonClassName: SELECTED_CLASS,
                    veryHighButtonClassName: "",
                    lowTooltipClassName: DISPLAY_NONE,
                    mediumTooltipClassName: DISPLAY_NONE,
                    highTooltipClassName: DISPLAY_BLOCK,
                    veryHighTooltipClassName: DISPLAY_NONE,
                });

                this.props.onChange(THRESHOLD_OPTIONS.HIGH);
                break;
            case  THRESHOLD_OPTIONS.VERY_HIGH:
                this.setState({
                    selected: THRESHOLD_OPTIONS.VERY_HIGH,
                    colorBarWidth: this.getColorBarWidth(THRESHOLD_OPTIONS.VERY_HIGH),
                    lowButtonClassName: SELECTED_CLASS,
                    mediumButtonClassName: SELECTED_CLASS,
                    highButtonClassName: SELECTED_CLASS,
                    veryHighButtonClassName: SELECTED_CLASS,
                    lowTooltipClassName: DISPLAY_NONE,
                    mediumTooltipClassName: DISPLAY_NONE,
                    highTooltipClassName: DISPLAY_NONE,
                    veryHighTooltipClassName: DISPLAY_BLOCK,
                });

                this.props.onChange(THRESHOLD_OPTIONS.VERY_HIGH);
                break;
            default:
        }
    }

    getColorBarWidth(selected) {
        return (this.getGroupWrapperWidth() / (Object.keys(THRESHOLD_OPTIONS).length - 1) * selected - 4);
    }

    updateDimensions() {
        this.setState(state => {
            return {
                colorBarWidth: this.getColorBarWidth(state.selected),
                lowButtonPosition: document.getElementById("custom-radio-group--low-button").offsetLeft,
                mediumButtonPosition: document.getElementById("custom-radio-group--medium-button").offsetLeft,
                highButtonPosition: document.getElementById("custom-radio-group--high-button").offsetLeft,
                veryHighButtonPosition: document.getElementById("custom-radio-group--very-high-button").offsetLeft,
            }
        });
    }

    getGroupWrapperWidth() {
        return document.getElementById("group-wrapper").clientWidth;
    }
}

ThresholdRateChooser.propTypes = {
    value: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ThresholdRateChooser;
