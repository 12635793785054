import './ConversationHealthChart.css';
import React, {Component} from 'react';
import Card from "react-bootstrap/Card";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import CustomTooltip from "./CustomTooltip";

class ConversationHealthChart extends Component {

    render() {
        const hateSpeechColor = "#fb5555";
        const personalAttackColor = "#8884d8";

        return (
            <Card style={{width: '100%'}}>
                <Card.Body>
                    <div style={{display: "flex"}}>
                        <div style={{flex: 2}}>
                            <Card.Title className="chart-title">Conversation Health</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted chart-subtitle">Harmful content in your feed</Card.Subtitle>
                        </div>
                        <div style={{flex: 1}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <span className={"color-preview"} style={{backgroundColor: personalAttackColor}}/>
                                <span>Personal Attack</span>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <span className={"color-preview"} style={{backgroundColor: hateSpeechColor}}/>
                                <span>Hate speech</span>
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <div style={{marginBottom: "-5px", borderRadius: "3px", overflow: "hidden", paddingTop: "5px", position: "relative"}}>
                    <div className="trend-y-axis">Detection Confidence</div>
                    <div className="trend-x-axis">
                        From {this.props.startDate.format('DD/MM/YYYY')} to {this.props.endDate.format('DD/MM/YYYY')}
                    </div>

                    <div style={{marginBottom: "20px"}}>
                        <ResponsiveContainer height={300} width='100%'>
                            <LineChart data={this.props.data} margin={{top: 5, right: 30, left: 20, bottom: 5}}>

                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip content={<CustomTooltip />}/>

                                <Line strokeWidth={3} type="monotone" dataKey="personalAttack"
                                      stroke={personalAttackColor}/>
                                <Line strokeWidth={3} type="monotone" dataKey="hateSpeech"
                                      stroke={hateSpeechColor}/>
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </Card>
        );
    }
}

export default ConversationHealthChart
