import React, {Component} from 'react';
import InputGroup from "react-bootstrap/InputGroup";
import TimePicker from "../util/TimePicker";

class CronHourly extends Component {

    constructor(props) {
        super(props);

        this.state = {
            minute: "0",
            hour: "0",
            hours: "1",
            everyNHours: true,
            specifiedHour: false,
        };

        this.onHoursChange = this.onHoursChange.bind(this);
        this.onHourChange = this.onHourChange.bind(this);
        this.onMinuteChange = this.onMinuteChange.bind(this);
        this.onRadioChange = this.onRadioChange.bind(this);
    }

    componentDidMount() {
        this.props.onChange(`0 0 */${this.state.hours} * * *`);
    }

    render() {
        return (
            <div className="cron-builder-content">

                <InputGroup className="m-2">
                    <InputGroup.Prepend>
                        <InputGroup.Radio checked={this.state.everyNHours}
                                          id="every-n-hours"
                                          name="hourly-cron-method"
                                          onChange={this.onRadioChange}
                                          aria-label="Radio button for every specified hours"/>
                    </InputGroup.Prepend>
                    <div className="radio-input-group-description">
                        <span>Every</span>
                        <input className="m-2"
                               type="number"
                               value={this.state.hours}
                               onChange={this.onHoursChange}
                               disabled={!this.state.everyNHours}/>
                        <span>hour(s)</span>
                    </div>
                </InputGroup>

                <InputGroup className="m-2">
                    <InputGroup.Prepend>
                        <InputGroup.Radio checked={this.state.specifiedHour}
                                          id="specified-hour"
                                          name="hourly-cron-method"
                                          onChange={this.onRadioChange}
                                          aria-label="Radio button for start at specified hour"/>
                    </InputGroup.Prepend>
                    <div className="radio-input-group-description">
                        <span>Starts at</span>

                        <TimePicker onMinuteChange={this.onMinuteChange}
                                    onHourChange={this.onHourChange}
                                    disabled={!this.state.specifiedHour}
                                    hour={this.state.hour}
                                    minute={this.state.minute}
                        />
                    </div>
                </InputGroup>
            </div>
        );
    }

    onHoursChange(event) {
        if (this.state.everyNHours) {
            const hours = event.target.value;

            if (hours && hours !== "" && hours > 0) {
                this.setState({hours: hours});

                this.props.onChange(`0 0 */${hours} * * *`)
            }
        }
    }

    onHourChange(event) {
        if (this.state.specifiedHour) {
            const hour = event.target.value;

            this.setState({hour: hour});

            this.props.onChange(`0 ${this.state.minute} ${hour} * * *`)
        }
    }

    onMinuteChange(event) {
        if (this.state.specifiedHour) {
            const minute = event.target.value;

            this.setState({minute: minute});

            this.props.onChange(`0 ${minute} ${this.state.hour} * * *`)
        }
    }

    onRadioChange(event) {
        switch (event.target.id) {
            case "specified-hour":
                this.setState({
                    specifiedHour: true,
                    everyNHours: false,
                });
                this.props.onChange(`0 ${this.state.minute} ${this.state.hour} * * *`);
                break;
            case "every-n-hours":
                this.setState({
                    specifiedHour: false,
                    everyNHours: true,
                });

                this.props.onChange(`0 0 */${this.state.hours} * * *`);
                break;
            default:
                break;
        }
    }
}

export default CronHourly;
