import cronParser from "cron-parser";

class CronUtils {

    static isValidCron(cron) {
        try {
            const interval = cronParser.parseExpression(cron);

            const firstEvaluation = CronUtils.getNextCronEvaluation(interval);
            const secondEvaluation = CronUtils.getNextCronEvaluation(interval);

            if (firstEvaluation != null && secondEvaluation != null) {
                const differenceInMinutes = ((secondEvaluation - firstEvaluation) / 1000) / 60;
                return differenceInMinutes >= parseInt(process.env.REACT_APP_MIN_CRON_DURATION_MINUTES);
            } else {
                return true;
            }
        } catch (e) {
            return false;
        }
    }

    static getNextCronEvaluation(interval) {
        if (interval.hasNext()) {
            return interval.next().toDate();
        } else {
            return null;
        }
    }
}

export default CronUtils;
