import React, {Component} from 'react';
import './Login.css';
import {TWITTER_AUTH_URL} from '../../../constants';
import {Link, Redirect} from 'react-router-dom'
import twitterLogo from '../../../img/twitter-logo.png';
import Alert from 'react-s-alert';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

class Login extends Component {

    componentDidMount() {
        // If the login encounters an error, the panel is redirected to the /login page with an error.
        // Here we display the error and then remove the error query parameter from the location.
        if (this.props.location.state && this.props.location.state.error) {
            setTimeout(() => {
                Alert.error(this.props.location.state.error, {
                    timeout: 5000
                });
                this.props.history.replace({
                    pathname: this.props.location.pathname,
                    state: {}
                });
            }, 100);
        }
    }

    render() {
        if (this.props.authenticated) {
            return <Redirect
                to={{
                    pathname: "/",
                    state: {from: this.props.location}
                }}/>;
        }

        return (
            <Container>
                <Row>
                    <Col>
                        <div className="shadow centered-box">
                            <h1 className="login-title">Login to DACHS Hate Detection alert monitoring</h1>
                            <SocialLogin/>
                            <span className="signup-link">New user? <Link to="/signup">Sign up!</Link></span>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

class SocialLogin extends Component {

    render() {
        return (
            <Form action={TWITTER_AUTH_URL} method="POST">
                <Form.Group as={Row}>
                    <Col>
                        <Button type="submit" variant="outline-primary" className="btn btn-block social-btn twitter">
                            <img src={twitterLogo} alt="Twitter"/>
                            Log in with Twitter
                        </Button>
                    </Col>
                </Form.Group>
            </Form>
        );
    }
}

export default Login
