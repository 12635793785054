import React, {Component} from 'react';

class Role extends Component {

    static renderRoleName(role) {
        if (role) {
            const roleLowerCase = role.toLowerCase();

            return roleLowerCase.charAt(0).toUpperCase() + roleLowerCase.slice(1);
        } else {
            return "";
        }
    }

    render() {
        const {role} = this.props;

        return (
            <div>
                {Role.renderRoleName(role.name)}
            </div>
        );
    }
}

export default Role;
