import React, {Component} from 'react';
import InputGroup from "react-bootstrap/InputGroup";
import TimePicker from "../util/TimePicker";

class CronDaily extends Component {

    constructor(props) {
        super(props);

        this.state = {
            minute: "0",
            hour: "0",
            days: "1",
            everyNDays: true,
            everyWeekDay: false,
        };

        this.onDaysChange = this.onDaysChange.bind(this);
        this.onHourChange = this.onHourChange.bind(this);
        this.onMinuteChange = this.onMinuteChange.bind(this);
        this.onRadioChange = this.onRadioChange.bind(this);
    }

    componentDidMount() {
        this.props.onChange(`0 ${this.state.minute} ${this.state.hour} */${this.state.days} * *`)
    }

    render() {
        return (
            <div className="cron-builder-content">
                <InputGroup className="m-2">
                    <InputGroup.Prepend>
                        <InputGroup.Radio checked={this.state.everyNDays}
                                          id="every-n-days"
                                          name="daily-cron-method"
                                          onChange={this.onRadioChange}/>
                    </InputGroup.Prepend>
                    <div className="radio-input-group-description">
                        <span>Every</span>
                        <input className="m-2"
                               type="number"
                               onChange={this.onDaysChange}
                               value={this.state.days}
                               disabled={!this.state.everyNDays}/>
                        <span>day(s)</span>
                    </div>
                </InputGroup>

                <InputGroup className="m-2">
                    <InputGroup.Prepend>
                        <InputGroup.Radio checked={this.state.everyWeekDay}
                                          id="every-week-day"
                                          name="daily-cron-method"
                                          onChange={this.onRadioChange}/>
                    </InputGroup.Prepend>
                    <div className="radio-input-group-description">
                        <span>Every Week Day</span>
                    </div>
                </InputGroup>


                <div className="m-2">
                    <span>
                        Start time
                    </span>

                    <TimePicker onMinuteChange={this.onMinuteChange}
                                onHourChange={this.onHourChange}
                                disabled={false}/>
                </div>

            </div>
        );
    }

    onDaysChange(event) {
        if (this.state.everyNDays) {
            const days = event.target.value;
            if (days && days !== "" && days > 0) {
                this.setState({days: days});

                this.props.onChange(`0 ${this.state.minute} ${this.state.hour} */${days} * *`)
            }
        }
    }

    onHourChange(event) {
        const hour = event.target.value;

        this.setState({hour: hour});

        if (this.state.everyNDays) {
            this.props.onChange(`0 ${this.state.minute} ${hour} */${this.state.days} * *`);
        } else if (this.state.everyWeekDay) {
            this.props.onChange(`0 ${this.state.minute} ${hour} * * 1-5`);
        }
    }

    onMinuteChange(event) {
        const minute = event.target.value;

        this.setState({minute: minute});

        if (this.state.everyNDays) {
            this.props.onChange(`0 ${minute} ${this.state.hour} */${this.state.days} * *`);
        } else if (this.state.everyWeekDay) {
            this.props.onChange(`0 ${minute} ${this.state.hour} * * 1-5`);
        }
    }

    onRadioChange(event) {
        switch (event.target.id) {
            case "every-week-day":
                this.setState({
                    everyWeekDay: true,
                    everyNDays: false,
                });
                this.props.onChange(`0 ${this.state.minute} ${this.state.hour} * * 1-5`);
                break;
            case "every-n-days":
                this.setState({
                    everyWeekDay: false,
                    everyNDays: true,
                });

                this.props.onChange(`0 ${this.state.minute} ${this.state.hour} */${this.state.days} * *`);
                break;
            default:
                break;
        }
    }
}

export default CronDaily;
