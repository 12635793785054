import React, {Component} from 'react';
import './NotFound.css';
import {Link} from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class NotFound extends Component {
    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="shadow centered-box">
                            <h1 className="title">
                                404 Not Found
                            </h1>

                            <div className="desc">
                                <p>The Page you're looking for was not found.</p>
                            </div>

                            <Link to="/">
                                <button className="go-back-btn btn btn-primary" type="button">Go Back</button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default NotFound;