import React, {Component} from 'react';
import './Profile.css';
import Container from "react-bootstrap/Container";
import PageHeader from "../../common/PageHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ProfileInfo from "./ProfileInfo";
import {getCurrentUser, updateCurrentUser, optOut} from '../../../util/APIUtils';
import ReactAlert from 'react-s-alert';
import Form from "react-bootstrap/Form";
import 'react-input-range/lib/css/index.css'
import cronstrue from 'cronstrue';
import Modal from "react-bootstrap/Modal";
import CronBuilder from "../../common/cron/CronBuilder";
import Button from "react-bootstrap/Button";
import CronUtils from "../../common/cron/CronUtils";
import Card from "react-bootstrap/Card";
import ToggleSwitch from "../../common/ToggleSwitch";
import ThresholdRateChooser from "../../common/ThresholdRateChooser";
import {THRESHOLD_OPTIONS} from "../../../constants";
import {Alert} from "react-bootstrap";


const defaultCron = '00 14 */1 * *';


class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: {
                firstName: '',
                lastName: '',
                email: '',
                activeReports: false,
                activeMonitoring: false,
                hateSpeechThreshold: THRESHOLD_OPTIONS.HIGH,
                reportFrequencyCron: defaultCron,
                roles: []
            },
            showCronBuilder: false,
            showOptOutModal: false,
        };

        this.onFirstNameChange = this.onFirstNameChange.bind(this);
        this.onLastNameChange = this.onLastNameChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onHateSpeechThresholdChange = this.onHateSpeechThresholdChange.bind(this);
        this.onCronChange = this.onCronChange.bind(this);
        this.onActiveMonitoringChange = this.onActiveMonitoringChange.bind(this);
        this.onActiveReportsChange = this.onActiveReportsChange.bind(this);
        this.onActiveLiveAlertsChange = this.onActiveLiveAlertsChange.bind(this);
        this.onCronBuilderShow = this.onCronBuilderShow.bind(this);
        this.onCronBuilderClose = this.onCronBuilderClose.bind(this);
        this.onProfileUpdate = this.onProfileUpdate.bind(this);
        this.onConfirmOptOutToggleShow = this.onConfirmOptOutToggleShow.bind(this);
        this.onConfirmOptOut = this.onConfirmOptOut.bind(this);
    }

    componentDidMount() {
        getCurrentUser()
            .then(user => {
                this.setState({
                    user: user,
                });
            })
            .catch(error => ReactAlert.error(error.message));
    }

    render() {
        const {user} = this.state;
        let cronHumanReadable = cronstrue.toString(user.reportFrequencyCron || defaultCron);

        return (
            <Container>
                <PageHeader title="Profile"/>

                <Row>
                    <Col sm={12} md={3} style={{marginBottom: "50px"}}>
                        <ProfileInfo user={user}/>
                    </Col>
                    <Col sm={12} md={9}>
                        <h4>User Preferences</h4>

                        <Form className="user-preferences-form">
                            <Card style={{width: '100%'}}>
                                <Card.Body>
                                    <Card.Title>Personal Information</Card.Title>
                                    {/*<Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>*/}

                                    <Form.Row>
                                        <Col xs={12} sm={6}>
                                            <Form.Group controlId="firstName">
                                                <Form.Label>First name</Form.Label>
                                                <Form.Control type="text"
                                                              value={user.firstName || ''}
                                                              onChange={this.onFirstNameChange}/>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Form.Group controlId="lastName">
                                                <Form.Label>Last name</Form.Label>
                                                <Form.Control type="text"
                                                              value={user.lastName || ''}
                                                              onChange={this.onLastNameChange}/>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col>
                                            <Form.Group controlId="email">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email"
                                                              value={user.email || ''}
                                                              onChange={this.onEmailChange}/>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>

                            <Card style={{width: '100%', marginTop: "20px"}}>
                                <Card.Body>

                                    <Form.Row>
                                        <div className={"card-title"}
                                             style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                            <h5 style={{margin: 0, lineHeight: "32px"}}>Alert Monitoring Settings</h5>
                                            <ToggleSwitch theme="default" className="d-flex"
                                                          enabled={user.activeMonitoring || false}
                                                          onStateChanged={this.onActiveMonitoringChange}/>
                                        </div>
                                    </Form.Row>

                                    <Form.Row>
                                        <Container>
                                            <Row>
                                                <Col xs={12} md={12} lg={12} xl={6}>
                                                    <Card style={{width: '100%', marginTop: "20px"}}>
                                                        <Card.Body>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox"
                                                                           className={"checkbox"}
                                                                           checked={user.activeLiveAlerts || false}
                                                                           onChange={this.onActiveLiveAlertsChange}
                                                                           disabled={!(user.activeMonitoring || false)}
                                                                    />
                                                                    Live alerts
                                                                </label>
                                                                <Alert variant="info"
                                                                       style={{fontSize: "13px", marginBottom: "10px"}}>If
                                                                    live alerts are enabled, the system sends an email
                                                                    when it detects at least one hate speech instance in
                                                                    a 15 minute window.
                                                                </Alert>
                                                            </div>
                                                            <div>
                                                                <Form.Label>Hate Speech threshold</Form.Label>

                                                                <Alert variant="info" style={{fontSize: "13px"}}>Configure
                                                                    the detection
                                                                    sensitivity
                                                                </Alert>

                                                                <ThresholdRateChooser
                                                                    value={user.hateSpeechThreshold ? user.hateSpeechThreshold : THRESHOLD_OPTIONS.MEDIUM}
                                                                    onChange={this.onHateSpeechThresholdChange}
                                                                    disabled={!(user.activeMonitoring || false) || !(user.activeLiveAlerts || false)}/>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col xs={12} md={12} lg={12} xl={6}>

                                                    <Card style={{width: '100%', marginTop: "20px"}}>
                                                        <Card.Body>
                                                            <div>
                                                                <label>
                                                                    <input type="checkbox"
                                                                           className={"checkbox"}
                                                                           checked={user.activeReports || false}
                                                                           onChange={this.onActiveReportsChange}
                                                                           disabled={!(user.activeMonitoring || false)}
                                                                    />
                                                                    Hate speech reports (Aggregated Statistics)
                                                                </label>

                                                                <Alert variant="info"
                                                                       style={{fontSize: "13px", marginBottom: "10px"}}>
                                                                    If hate speech reports are enabled the system sends
                                                                    an
                                                                    email with a snapshot of your dashboard for the
                                                                    calculated time period (based on the selected
                                                                    frequency)
                                                                </Alert>
                                                            </div>

                                                            <div>
                                                                <Form.Group controlId="reportFrequencyCron">
                                                                    <Form.Label>Report Frequency</Form.Label>

                                                                    <div style={{marginTop: "-10px"}}>
                                                                        <strong>{cronHumanReadable}</strong>
                                                                        <Button variant="outline-primary"
                                                                                className="m-2"
                                                                                disabled={(!user.activeReports || false) || (!user.activeMonitoring || false)}
                                                                                onClick={this.onCronBuilderShow}>
                                                                            Change
                                                                        </Button>

                                                                        <Modal show={this.state.showCronBuilder}
                                                                               onHide={this.onCronBuilderClose}
                                                                               dialogClassName="cron-builder-modal">
                                                                            <Modal.Header closeButton>
                                                                                <Modal.Title>Change Report
                                                                                    Frequency</Modal.Title>
                                                                            </Modal.Header>
                                                                            <Modal.Body>
                                                                                <CronBuilder
                                                                                    value={user.reportFrequencyCron ? user.reportFrequencyCron : defaultCron}
                                                                                    isValidCron={CronUtils.isValidCron}
                                                                                    onSubmit={this.onCronChange}/>
                                                                            </Modal.Body>
                                                                        </Modal>
                                                                    </div>
                                                                </Form.Group>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Form.Row>

                                    <Form.Row>
                                        <Col>
                                            <Button variant="primary" className="m-2 float-right"
                                                    onClick={this.onProfileUpdate}>
                                                Update
                                            </Button>
                                        </Col>
                                    </Form.Row>

                                </Card.Body>
                            </Card>

                            <Card style={{width: '100%', marginTop: "20px", marginBottom: "70px"}}>
                                <Card.Body>
                                    <Form.Row>
                                        <div className={"card-title"}
                                             style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                                            <h5 style={{margin: 0, lineHeight: "32px"}}>Opt Out</h5>

                                            <Button variant="outline-danger" className="d-flex float-right"
                                                    onClick={this.onConfirmOptOutToggleShow}>
                                                Forget me and Delete my account
                                            </Button>
                                        </div>
                                        <Modal show={this.state.showOptOutModal} onHide={this.onConfirmOptOutToggleShow}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Opt Out</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <h4>Read carefully.</h4>
                                                <p>
                                                    You are trying to delete your Account, which provides access
                                                    to Hate Speech and Personal attack alerting services. You will no
                                                    longer be able to use any of these services, and your account and
                                                    data will be lost.
                                                </p>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={this.onConfirmOptOutToggleShow}>
                                                    Close
                                                </Button>
                                                <Button variant="danger" onClick={this.onConfirmOptOut}>
                                                    Forget me and Delete my account
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }

    onConfirmOptOutToggleShow() {
        this.setState({showOptOutModal: !this.state.showOptOutModal})
    }

    onConfirmOptOut() {
        optOut()
            .then(result => {
                ReactAlert.success("Successful opt out");
                this.props.onLogout()
            })
            .catch(reason => {
                ReactAlert.error("Could not opt out.");
            })
    }

    onFirstNameChange(event) {
        const user = this.state.user;
        user.firstName = event.target.value;

        this.setState({user: user});
    }

    onLastNameChange(event) {
        const user = this.state.user;
        user.lastName = event.target.value;

        this.setState({user: user});
    }

    onEmailChange(event) {
        const user = this.state.user;
        user.email = event.target.value;

        this.setState({user: user});
    }

    onHateSpeechThresholdChange(value) {
        const user = this.state.user;
        user.hateSpeechThreshold = value;

        this.setState({user: user});
    }

    onCronChange(value) {
        const user = this.state.user;
        user.reportFrequencyCron = value;

        this.setState({
            user: user,
            showCronBuilder: false
        });
    }

    onActiveReportsChange(event) {
        const user = this.state.user;
        user.activeReports = event.target.checked;

        this.setState({user: user});
    }

    onActiveLiveAlertsChange(event) {
        const user = this.state.user;
        user.activeLiveAlerts = event.target.checked;

        this.setState({user: user});
    }

    onActiveMonitoringChange(state) {
        const user = this.state.user;
        user.activeMonitoring = state.enabled;

        this.setState({user: user});
    }

    onCronBuilderShow() {
        this.setState({showCronBuilder: true});
    }

    onCronBuilderClose() {
        this.setState({showCronBuilder: false});
    }

    onProfileUpdate() {
        updateCurrentUser(this.state.user)
            .then(result => {
                ReactAlert.success("Profile successfully updated");
            })
            .catch(reason => {
                ReactAlert.error("Could not update profile.");
            })
    }
}

export default Profile;
