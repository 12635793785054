import React, {Component} from 'react';
import './Signup.css';
import {Link, Redirect} from 'react-router-dom'
import {TWITTER_AUTH_URL} from '../../../constants';
import twitterLogo from '../../../img/twitter-logo.png';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

class Signup extends Component {

    render() {
        if (this.props.authenticated) {
            return <Redirect
                to={{
                    pathname: "/",
                    state: {from: this.props.location}
                }}/>;
        }

        return (
            <Container>
                <Row>
                    <Col>
                        <div className="shadow centered-box">
                            <h1 className="signup-title">Signup to DACHS Hate Detection alert monitoring</h1>
                            <SocialSignup/>
                            <span className="login-link">Already have an account? <Link to="/login">Login!</Link></span>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}


class SocialSignup extends Component {
    render() {
        return (
            <Form action={TWITTER_AUTH_URL} method="POST">
                <Form.Group as={Row}>
                    <Col>
                        <Button type="submit" variant="outline-primary" className="btn btn-block social-btn twitter">
                            <img src={twitterLogo} alt="Twitter"/>
                            Sign up with Twitter
                        </Button>
                    </Col>
                </Form.Group>
            </Form>
        );
    }
}

export default Signup