import './TrendChart.css';
import React, {Component} from 'react';
import Card from "react-bootstrap/Card";
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import CustomTooltip from "./CustomTooltip";

class TrendChart extends Component {

    render() {
        const hateSpeechColor = "#fb5555";
        const personalAttachColor = "#8884d8";

        return (
            <Card style={{width: '100%'}}>
                <Card.Body>
                    <div style={{display: "flex"}}>
                        <div style={{flex: 2}}>
                            <Card.Title className="chart-title">Trend</Card.Title>
                            <Card.Subtitle className="mb-2 text-muted chart-subtitle">
                                Percentage of change per 15 minutes
                            </Card.Subtitle>
                        </div>
                        <div style={{flex: 1}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <span className={"color-preview"} style={{backgroundColor: personalAttachColor}}/>
                                <span>Personal attack</span>
                            </div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <span className={"color-preview"} style={{backgroundColor: hateSpeechColor}}/>
                                <span>Hate speech</span>
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <div style={{marginBottom: "-5px", borderRadius: "3px", overflow: "hidden", paddingTop: "5px", position: "relative"}}>
                    <div className="trend-y-axis">Detection Confidence</div>
                    <div className="trend-x-axis">
                        From {this.props.startDate.format('DD/MM/YYYY')} to {this.props.endDate.format('DD/MM/YYYY')}
                    </div>
                    <div style={{marginBottom: "20px"}}>
                        <ResponsiveContainer height={300} width="100%">
                            <AreaChart data={this.props.data} margin={{ top: 5, right: 0, left: 0, bottom: 5}}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor={personalAttachColor} stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor={personalAttachColor} stopOpacity={0.1}/>
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor={hateSpeechColor} stopOpacity={0.8}/>
                                        <stop offset="95%" stopColor={hateSpeechColor} stopOpacity={0.1}/>
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date" />
                                <YAxis />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip content={<CustomTooltip />}/>

                                <Area type="monotone" dataKey="personalAttack" stroke={personalAttachColor} strokeWidth={2}
                                      fillOpacity={1} fill="url(#colorUv)"/>
                                <Area type="monotone" dataKey="hateSpeech" stroke={hateSpeechColor} strokeWidth={2}
                                      fillOpacity={1} fill="url(#colorPv)"/>
                            </AreaChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </Card>
        );
    }
}

export default TrendChart
