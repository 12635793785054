import React from "react";
import * as moment from 'moment';

const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1)
};

const unCamelCase = (s) => {
    if (typeof s !== 'string') return '';
    return s.replace(/([a-z])([A-Z])/g, '$1 $2')
};

const CustomTooltip = ({active, payload, label}) => {
    if (active) {
        if (payload && payload.length === 2) {
            const first = payload[0];
            const second = payload[1];

            const date = moment(Number(first.payload.id)).format('dddd, MMMM Do YYYY, h:mm:ss a');

            return (
                <div style={{background: "#fff", border: "2px solid #787878", padding: "10px 20px 0px", borderRadius: "3px"}}>
                    <p className="label"><strong>{`${date}`}</strong></p>
                    <p className="label" style={{color: first.color}}>{`${capitalize(unCamelCase(first.name))} : ${first.value}`}%</p>
                    <p className="label" style={{color: second.color}}>{`${capitalize(unCamelCase(second.name))} : ${second.value}`}%</p>
                </div>
            );
        }
    }

    return null;
};


export default CustomTooltip
